import { ROUTES } from '@/router/routesEnum';
import { TblUpdateCondition } from '@/shared/model/tblUpdateCondition';
import { CompletedInfo } from '@/shared/model/tblUpdateSupplier';
import { OdataItems } from '@/shared/model/OdataItems';
import { SearchParams } from '@/shared/model/searchParams';
import { CONST } from '@/shared/utils/Constants';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Contact } from '@/shared/model/contact';
import { namespace } from 'vuex-class';
import GeneralUtils from '@/shared/utils/generalUtils';
import D4yTable from '@/components/d4y-table/d4y-table.vue';
import DateField from '@/components/_common/date-field/DateField.vue';
import searchData from '@/shared/model/smallPayloadModels/searchData';
import { CompanyWrGruppenResult, LieferantengruppenResult } from '@/shared/model/company';

const name = 'tblUpdateConditions-view';
const contactModule = namespace('contact');
const tblUpdateConditionModule = namespace('tblUpdateCondition');
const tblUpdateSupplierModule = namespace('tblUpdateSupplier');
const authModule = namespace('auth');

@Component({ name: name, components: { D4yTable, DateField } })
export default class TblUpdateConditionsComponent extends Vue {
  @tblUpdateConditionModule.Action('getTblUpdateConditions')
  private actionGettblUpdateConditions!: any;
  @tblUpdateConditionModule.Getter('getTblUpdateConditions')
  private tblUpdateConditions!: OdataItems<TblUpdateCondition>;
  @tblUpdateConditionModule.Getter('getTblUpdateConditionsSearchParams')
  private tblUpdateConditionsSearchParams!: SearchParams;
  @tblUpdateConditionModule.Getter('getTblUpdateConditionsIsLoading')
  private tblUpdateConditionsIsLoading!: boolean;
  @tblUpdateConditionModule.Action('deleteTblUpdateCondition')
  private actionDeleteTblUpdateCondition!: any;
  @tblUpdateConditionModule.Action('getTblUpdateConditionsCompleted')
  private tblUpdateConditionsCompleted!: any;
  @tblUpdateConditionModule.Action('getAddConditionCompleted')
  private actionAddConditionCompleted!: any;
  @tblUpdateConditionModule.Getter('getTblUpdateConditionrsTotal')
  private tblUpdateConditionrsTotal!: number;
  @tblUpdateConditionModule.Getter('getDashboardDate')
  private dashboardDate!: any;
  @contactModule.Getter('getContactForLoggedInUser')
  private contactForLoggedInUser!: Contact;
  @tblUpdateSupplierModule.Action('getCompanyWrGruppen')
  private actionGetCompanyWrGruppen!: any;
  @tblUpdateConditionModule.Getter('getDashboarCompanyWrGruppen')
  private dashboardCompanyWrGruppen!: any;
  @tblUpdateSupplierModule.State('lieferantengruppen')
  private lieferantengruppenList!: any; 
  @tblUpdateConditionModule.Action('updateDashboardDate')
  private actionUpdateConditionDashboardDate!: any;
  @tblUpdateSupplierModule.Action('updateDashboardDate')
  private actionUpdateDashboardDate!: any;
  @tblUpdateSupplierModule.Getter('getDashboardLieferantengruppen')
  private dashboardLieferantengruppen!: any;

  @tblUpdateConditionModule.Action('updateDashboardConditionsStatus')
  private actionGetDashboardConditionsStatus!: any;
  @tblUpdateConditionModule.Getter('getDashboadrdConditionsStatus')
  private dashboardConditionsStatus!: any;
  @tblUpdateConditionModule.Action('getCanselConditionCompleted')
  private actionCanselConditionCompleted!: any;

  companyWrGruppen: CompanyWrGruppenResult[] = [];
  lieferantengruppen: LieferantengruppenResult[]= [];
  private companiesWrGruppen: string[] = [];
  private lieferantenGruppen: string[] = [];
  private selectedStatus: string = '';

  @authModule.Getter('azureUser')
  private azureUser!: any;
  @authModule.Getter('azureUserEmail')
  private azureEmail!: any;

  dateSearch: any = '';
  changeDate(value: any) {
    // prevent strange 2 request with the same value
    if (this.dateSearch == value) {
      return;
    }
    this.dateSearch = value || undefined; // new Date(value).toJSON();
    // this.triggerSearchDataUpdate();
    this.actionGettblUpdateConditions({
      searchParams: this.tblUpdateConditionsSearchParams,
      conditionSearchData: {
        datum: this.dateSearch,
        companiesWrGruppen: this.companiesWrGruppen,
        lieferantenGruppen: this.lieferantenGruppen,
        contactEmail: this.azureEmail,
        statusCondition: this.selectedStatus,
      },
    });

    this.actionUpdateConditionDashboardDate(value);
    this.actionUpdateDashboardDate();
  }

  created() {
    this.lieferantengruppen=this.lieferantengruppenList;
    if (this.dashboardDate) {
      this.dateSearch = this.dashboardDate;
    }
    if (this.dashboardCompanyWrGruppen) {
      this.companiesWrGruppen = this.dashboardCompanyWrGruppen;
    }

    if (this.dashboardConditionsStatus) {
      this.selectedStatus = this.dashboardConditionsStatus;
    } else {
      this.selectedStatus = 'all';
    }
    if (this.dashboardLieferantengruppen) {
      this.lieferantenGruppen = this.dashboardLieferantengruppen;
    }
  }

  isInitialRequest = true;
  async mounted() {
    var promiseAll = [this.getTblUpdateConditions(), this.getCompanyZrsFromBackend()];
    await Promise.all(promiseAll);
    this.isInitialRequest = false;
  }

  private async getTblUpdateConditions() {
    console.log('this.azureEmail :>> ', this.azureEmail);
    await this.actionGettblUpdateConditions({
      searchParams: this.tblUpdateConditionsSearchParams,
      conditionSearchData: {
        datum: this.dateSearch,
        contactEmail: this.azureEmail,
        companiesWrGruppen: this.companiesWrGruppen,
        lieferantenGruppen: this.lieferantenGruppen,
        statusCondition: this.selectedStatus,
      },
    });
  }

  get headers() {
    let headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
      align?: string;
    }[] = [
      { text: this.$t('number'), value: 'cnd_zrnummer' },
      { text: this.$t('name'), value: 'cnd_name' },
      { text: this.$t('updated_on'), value: 'cnd_changedate' },
      //{ text: this.$t('changed_fields'), value: 'cnd_changefields' }, // ds, 09.09.2024 -> according to the item AD-164
      { text: this.$t('changed_values'), value: 'cnd_changevalue' },
      { text: this.$t('conditions_list_completed'), value: 'conditionDoneTimeCompleted' },
      {
        text: this.$t('actions'),
        value: 'actions',
        sortable: false,
        width: '1%',
        align: 'center',
      },
    ];
    return headers;
  }

  get image() {
    return {};
    return {
      src: 'img_tblUpdateSupplier.png',
      header: this.$t('title'),
      width: 360,
      height: 200,
    };
  }

  onAdd() {
    this.add();
  }

  add() {
    // this.$router.push({
    //   name: ROUTES.tblUpdateSupplier,
    //   params: {
    //     tblUpdateSupplierId: CONST.emptyGuid,
    //   },
    // });
  }

  get isRealXl() {
    return this.$vuetify.breakpoint.width < 1920;
  }

  get getFilterFields() {
    return this.tblUpdateConditionsSearchParams.orClauseFieldsIds
      .map((x) => this.$t(GeneralUtils.camelToSnakeCase(x)))
      .toString();
  }

  private async completedCondition(tblUpdateCondition: any) {
    if (tblUpdateCondition.conditionDoneTimeCompleted.length == 0) {
      this.actionAddConditionCompleted({
        id: tblUpdateCondition.id,
        zrMaster: this.contactForLoggedInUser ? this.contactForLoggedInUser?.zrMaster : null,
        contactEmail: this.azureEmail,
      }).then((completedInfo: CompletedInfo) => {
        tblUpdateCondition.conditionDoneTimeCompleted = completedInfo.completedDoneTime;
        tblUpdateCondition.conditionUserNameCompleted = completedInfo.completedUserName;
      });
    } else {
      this.$confirm
        .open(`${this.$t('completed_dialog_title')}`, `${this.$t('completed_dialog_title_text')}`, {
          cancelText: this.$t('delete_dialog_cancel'),
          okText: this.$t('delete_dialog_ok'),
        })
        .then(async (response: any) => {
          if (response) {
            this.actionCanselConditionCompleted({
              id: tblUpdateCondition.id,
              zrMaster: this.contactForLoggedInUser ? this.contactForLoggedInUser?.zrMaster : null,
              contactEmail: this.azureEmail,
            }).then((completedInfo: CompletedInfo) => {
              tblUpdateCondition.conditionDoneTimeCompleted = '';
              tblUpdateCondition.conditionUserNameCompleted = '';
            });
          }
        });
    }
  }

  private async tblUpdateConditionsUpdateOptions(newVal: any, oldVal: any) {
    this.tblUpdateConditionsSearchParams.dataOption = newVal;
    this.tblUpdateConditionsSearchParams.filter = newVal.filter;
    // `oldVal` is always `undefined`
    if (newVal != oldVal && this.tblUpdateConditionsIsLoading != undefined && !this.isInitialRequest) this.getTblUpdateConditions();
  }

  deleteTblUpdateCondition(editedTblUpdateCondition: TblUpdateCondition) {
    return;
    this.$confirm
      .open(`${this.$t('delete_dialog_title')}`, `${this.$t('delete_dialog_text')}`, {
        cancelText: this.$t('delete_dialog_cancel'),
        okText: this.$t('delete_dialog_ok'),
      })
      .then(async (response: any) => {
        if (response) {
          await this.actionDeleteTblUpdateCondition(editedTblUpdateCondition.id).then(() => {
            this.getTblUpdateConditions();
          });
        }
      });
  }

  private async getCompanyZrsFromBackend() {
    // const payload = {
    //   isInSuperAdminOrSupportGroup: false,
    //   contactType: contactType,
    //   IsActive: this.searchData.isActive,
    // };
    await this.actionGetCompanyWrGruppen({ searchData: { contactEmail: this.azureEmail } })
      .then((result: CompanyWrGruppenResult[]) => {
        this.companyWrGruppen = result;
      })
      .catch((err: any) => {});
  }

  changeCompanyWrGruppen() {
    this.getTblUpdateConditions();
  }

  changeLieferantengruppen() {
    this.getTblUpdateConditions();
  }

  changeSelectStatus() {
    this.getTblUpdateConditions();
  }

  closeConditions() {
    this.$router.push({
      name: ROUTES.tasks,
    });
  }

  getFormatingText(conditionChangeValue: any) {
    let conditionText: string = '';
    if (conditionChangeValue != null) {
      conditionText = conditionChangeValue.replaceAll('\n', '<br>');
    }
    return conditionText;
  }

  openCondition(zrnummer: string) {
    let url: any =
      'https://einrichtungspartnerringvme.sharepoint.com/sites/VME-Einkauf/SitePages/lieferanten.aspx?' + zrnummer;
    window.open(url, '_blank');
  }

  getZrNummer(item: TblUpdateCondition) {
    return item.cnd_zrnummer;
  }

  activeState = [
    {
      text: this.$i18n.t(`select_all`), //'Alle',
      value: 'all',
    },
    {
      text: this.$i18n.t(`select_offen`), //'Offen',
      value: 'open',
    },
    {
      text: this.$i18n.t(`select_erledigt`), //'Erledigt',
      value: 'done',
    },
  ];

}

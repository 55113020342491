import { ROUTES } from '@/router/routesEnum';
import tblUpdateGPSRProdVOFile, {
  TblUpdateGPSRProdVOFile,
  ZRNummerListResult,
} from '@/shared/model/tblUpdateGPSRProdVOFile';
import { CompletedInfo } from '@/shared/model/tblUpdateSupplier';
import { OdataItems } from '@/shared/model/OdataItems';
import { SearchParams } from '@/shared/model/searchParams';
import { CONST } from '@/shared/utils/Constants';
import Vue from 'vue';
import DateUtils from '@/shared/utils/DateUtils';
import { Contact } from '@/shared/model/contact';
import { Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import GeneralUtils from '@/shared/utils/generalUtils';
import D4yTable from '@/components/d4y-table/d4y-table.vue';
import DateField from '@/components/_common/date-field/DateField.vue';
import searchData from '@/shared/model/smallPayloadModels/searchData';
import { TblUpdateCondition } from '@/shared/model/tblUpdateCondition';

const name = 'tblUpdateGPSRProdVOFiles-view';
const contactModule = namespace('contact');
const tblUpdateGPSRProdVOFileModule = namespace('tblUpdateGPSRProdVOFile');
const tblUpdateSupplierModule = namespace('tblUpdateSupplier');
const tblUpdateConditionModule = namespace('tblUpdateCondition');
const authModule = namespace('auth');

@Component({ name: name, components: { D4yTable, DateField } })
export default class TblUpdateGPSRProdVOFilesComponent extends Vue {
  @tblUpdateGPSRProdVOFileModule.Action('getTblUpdateGPSRProdVOFiles')
  private actionGettblUpdateGPSRProdVOFiles!: any;
  @tblUpdateGPSRProdVOFileModule.Getter('getTblUpdateGPSRProdVOFiles')
  private tblUpdateGPSRProdVOFiles!: OdataItems<TblUpdateGPSRProdVOFile>;
  @tblUpdateGPSRProdVOFileModule.Getter('getTblUpdateGPSRProdVOFilesSearchParams')
  private tblUpdateGPSRProdVOFilesSearchParams!: SearchParams;
  @tblUpdateGPSRProdVOFileModule.Getter('getTblUpdateGPSRProdVOFilesIsLoading')
  private tblUpdateGPSRProdVOFilesIsLoading!: boolean;
  @tblUpdateGPSRProdVOFileModule.Getter('getTblUpdateGPSRProdVOFilesTotal')
  private tblUpdateGPSRProdVOFilesTotal!: number;
  @tblUpdateGPSRProdVOFileModule.Action('getAddGPSRProdVOFileCompleted')
  private actionAddGPSRProdVOFileCompleted!: any;
  @tblUpdateConditionModule.Action('updateDashboardDate')
  private actionUpdateConditionDashboardDate!: any;
  @tblUpdateSupplierModule.Action('updateDashboardDate')
  private actionUpdateDashboardDate!: any;
  @tblUpdateGPSRProdVOFileModule.Action('updateDashboardDate')
  private actionUpdateGPSRProdVOFileDashboardDate!: any;
  @tblUpdateGPSRProdVOFileModule.Action('getCanselGPSRProdVOFileCompleted')
  private actionCanselGPSRProdVOFileCompleted!: any;
  @tblUpdateGPSRProdVOFileModule.Action('downloadScripxFile')
  private actionDownloadScripxFile!: any;
  @tblUpdateGPSRProdVOFileModule.Action('getZRNummerList')
  private actionGetZRNummerList!: any;

  @tblUpdateGPSRProdVOFileModule.Action('downloadArtusFiles')
  private actionDownloadArtusFiles!: any;

  @tblUpdateGPSRProdVOFileModule.Getter('getDashboardDate')
  private dashboardDate!: any;
  @contactModule.Getter('getContactForLoggedInUser')
  private contactForLoggedInUser!: Contact;
  @tblUpdateGPSRProdVOFileModule.Getter('getDashboadrdGPSRProdVOFilesStatus')
  private dashboardGPSRProdVOFilesStatus!: any;

  @authModule.Getter('azureUser')
  private azureUser!: any;
  @authModule.Getter('azureUserEmail')
  private azureEmail!: any;
  private selectedStatus: string = '';
  private selectedZRNummer: string[] = [];
  listZRNummer: ZRNummerListResult[] = [];
  loadingFiles: boolean = false;

  dateSearch: any = '';
  private searchTerm = '';
  @Watch('tblUpdateGPSRProdVOFilesSearchParams.filter', { deep: true })
  public async onOptionsFilterChanged(newValFilter: string, oldValFilter: string) {
    if (newValFilter == oldValFilter) {
      return;
    }

    this.tblUpdateGPSRProdVOFilesSearchParams.dataOption.page = 1;
    this.actionGettblUpdateGPSRProdVOFiles({
      searchParams: this.tblUpdateGPSRProdVOFilesSearchParams,
      gpsrprodVOSearchData: {
        datum: this.dateSearch,
        contactEmail: this.azureEmail,
        statusGPSRProdVOFile: this.selectedStatus,
        selectedZRNummer: this.selectedZRNummer,
      },
    });
  }

  changeDate(value: any) {
    // prevent strange 2 request with the same value
    if (this.dateSearch == value) {
      return;
    }
    this.dateSearch = value || undefined; // new Date(value).toJSON();
    // this.triggerSearchDataUpdate();
    this.actionGettblUpdateGPSRProdVOFiles({
      searchParams: this.tblUpdateGPSRProdVOFilesSearchParams,
      gpsrprodVOSearchData: {
        datum: this.dateSearch,
        contactEmail: this.azureEmail,
        statusGPSRProdVOFile: this.selectedStatus,
        selectedZRNummer: this.selectedZRNummer,
      },
    });
    this.actionUpdateConditionDashboardDate(value);
    this.actionUpdateDashboardDate(value);
    this.actionUpdateGPSRProdVOFileDashboardDate(value);
    this.getlistZRNummerFromBackend();
  }

  created() {
    this.dateSearch = this.dashboardDate;
    if (this.dashboardGPSRProdVOFilesStatus) {
      this.selectedStatus = this.dashboardGPSRProdVOFilesStatus;
    } else {
      this.selectedStatus = 'all';
    }
  }

  isInitialRequest = true;
  async mounted() {
    var promiseAll = [this.getTblUpdateGPSRProdVOFiles(), this.getlistZRNummerFromBackend()];
    await Promise.all(promiseAll);
    this.isInitialRequest = false;
  }

  private async getTblUpdateGPSRProdVOFiles() {
    await this.actionGettblUpdateGPSRProdVOFiles({
      searchParams: this.tblUpdateGPSRProdVOFilesSearchParams,
      gpsrprodVOSearchData: {
        datum: this.dateSearch,
        contactEmail: this.azureEmail,
        statusGPSRProdVOFile: this.selectedStatus,
        selectedZRNummer: this.selectedZRNummer,
      },
    });
  }

  get headers() {
    let headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
      align?: string;
    }[] = [
      {
        text: this.$t(''),
        value: 'data-table-select',
        sortable: false,
      },
      { text: this.$t('zr_nummer'), value: 'gpsr_zrnummer', width: '8%' },
      { text: this.$t('file_name'), value: 'gpsr_filename', width: '40%' },
      { text: this.$t('file_daten'), value: 'gpsr_filedate', width: '100px' },
      { text: this.$t('files_list_completed'), value: 'gpsrFileDoneTimeCompleted' },
      {
        text: this.$t('actions'),
        value: 'actions',
        sortable: false,
        width: '2%',
        align: 'center',
      },
    ];
    return headers;
  }

  get image() {
    return {};
    return {
      src: 'img_tblUpdateSupplier.png',
      header: this.$t('title'),
      width: 360,
      height: 200,
    };
  }

  selected: TblUpdateGPSRProdVOFile[] = [];
  get isDownload() {
    return this.selected?.length > 0;
  }

  enterSelect(values: any) {
    if (values?.length > 0) {
      this.selected = values;
    } else {
      this.selected = [];
    }
  }

  onAdd() {
    this.add();
  }

  add() {
    // this.$router.push({
    //   name: ROUTES.tblUpdateSupplier,
    //   params: {
    //     tblUpdateSupplierId: CONST.emptyGuid,
    //   },
    // });
  }

  get isRealXl() {
    return this.$vuetify.breakpoint.width < 1920;
  }

  get getFilterFields() {
    return this.tblUpdateGPSRProdVOFilesSearchParams.orClauseFieldsIds
      .map((x) => this.$t(GeneralUtils.camelToSnakeCase(x)))
      .toString();
  }

  private async tblUpdateGPSRProdVOFilesUpdateOptions(newVal: any, oldVal: any) {
    this.tblUpdateGPSRProdVOFilesSearchParams.dataOption = newVal;
    // `oldVal` is always `undefined`
    if (newVal != oldVal && this.tblUpdateGPSRProdVOFilesIsLoading != undefined && !this.isInitialRequest)
      this.getTblUpdateGPSRProdVOFiles();
  }

  private async completedGPSRProdVOFile(tblUpdateGPSRProdVOFile: any) {
    if (tblUpdateGPSRProdVOFile.gpsrFileDoneTimeCompleted?.length == 0) {
      this.actionAddGPSRProdVOFileCompleted({
        id: tblUpdateGPSRProdVOFile.id,
        zrMaster: this.contactForLoggedInUser ? this.contactForLoggedInUser?.zrMaster : null,
        contactEmail: this.azureEmail,
        statusGPSRProdVOFile: this.selectedStatus,
        selectedZRNummer: this.selectedZRNummer,
      }).then((completedInfo: CompletedInfo) => {
        tblUpdateGPSRProdVOFile.gpsrFileDoneTimeCompleted = DateUtils.formatDateTimeWithLocaleAndTimezone(
          completedInfo.completedDoneTime
        );
        tblUpdateGPSRProdVOFile.gpsrFileUserNameCompleted = completedInfo.completedUserName;
        this.getTblUpdateGPSRProdVOFiles();
      });
    } else {
      this.$confirm
        .open(`${this.$t('completed_dialog_title')}`, `${this.$t('completed_dialog_title_text')}`, {
          cancelText: this.$t('delete_dialog_cancel'),
          okText: this.$t('delete_dialog_ok'),
        })
        .then(async (response: any) => {
          if (response) {
            this.actionCanselGPSRProdVOFileCompleted({
              id: tblUpdateGPSRProdVOFile.id,
              zrMaster: this.contactForLoggedInUser ? this.contactForLoggedInUser?.zrMaster : null,
              contactEmail: this.azureEmail,
              statusGPSRProdVOFile: this.selectedStatus,
              selectedZRNummer: this.selectedZRNummer,
            }).then((completedInfo: CompletedInfo) => {
              tblUpdateGPSRProdVOFile.gpsrFileDoneTimeCompleted = '';
              tblUpdateGPSRProdVOFile.gpsrFileUserNameCompleted = '';
              this.getTblUpdateGPSRProdVOFiles();
            });
          }
        });
    }
  }

  get title() {
    return this.$t('title');
  }

  changeSelectStatus() {
    this.getTblUpdateGPSRProdVOFiles();
    this.getlistZRNummerFromBackend();
  }

  get labelTitel() {
    if (this.$route.fullPath.includes('/suppliers')) {
      return this.$t('supplier_status');
    } else {
      return this.$t('employee_status');
    }
  }

  closeGPSRProdVOFiles() {
    this.tblUpdateGPSRProdVOFilesSearchParams.filter = '';
    this.$router.push({
      name: ROUTES.tasks,
    });
  }

  private changeSearch(searchTerm: string) {
    if (searchTerm.length < 2) return;
    this.searchGPSRProdVOFiles(searchTerm);
  }
  private changeSearchInput(value: string) {
    if (value == null || value.length == 0) {
      this.resetSearch();
    }
  }
  private resetSearch() {
    const emptyString = '';
    this.searchGPSRProdVOFiles(emptyString);
  }

  private async searchGPSRProdVOFiles(filterValue: string) {
    this.tblUpdateGPSRProdVOFilesSearchParams.filter = filterValue; // `this.get ContactsSearch` will be called via `@Watch`
    // if (!this.contactsIsLoading) this.get ContactsSearch();
  }

  get titleSearch() {
    if (this.$route.fullPath.includes('/tasks/werbung')) {
      return this.$t('search_Werbung');
    } else {
      return this.$t('search_Document');
    }
  }

  openGPSRProdVOFile(item: TblUpdateGPSRProdVOFile) {
    if (item.isDownloading) return;

    // let url: any =
    //   'https://intranet.meinvme.de/php/intranet/ZAblage/SCANPRIX/2025/' + item.scp_fileorder + '/' +
    //   item.scp_filename;
    // url ='https://pdfobject.com/pdf/sample.pdf';
    // url='https://www2.hu-berlin.de/stadtlabor/wp-content/uploads/2021/12/sample3.docx';
    // window.open(url, '_blank');
    var payload = {
      fileName: item.gpsr_filename,
    };
    // (AD-201) different download logic for `localDev`(stub link) and other ENVs(download from Azure)

    item.isDownloading = true;
    this.actionDownloadScripxFile(payload).finally(() => {
      setTimeout(() => {
        item.isDownloading = false;
      }, 500);
    });
  }

  private async getlistZRNummerFromBackend() {
    await this.actionGetZRNummerList({
      searchParams: this.tblUpdateGPSRProdVOFilesSearchParams,
      gpsrprodVOSearchData: {
        datum: this.dateSearch,
        contactEmail: this.azureEmail,
        statusGPSRProdVOFile: this.selectedStatus,
      },
    })
      .then((result: ZRNummerListResult[]) => {
        this.listZRNummer = result;
      })
      .catch((err: any) => {});
  }

  changeSelectZRNummer() {
    this.getTblUpdateGPSRProdVOFiles();
  }

  activeState = [
    {
      text: this.$i18n.t(`select_all`), //'Alle',
      value: 'all',
    },
    {
      text: this.$i18n.t(`select_offen`), //'Offen',
      value: 'open',
    },
    {
      text: this.$i18n.t(`select_erledigt`), //'Erledigt',
      value: 'done',
    },
  ];

  private selection?: {
    showSelect: boolean;
    singleSelect?: boolean;
    customHeader?: boolean;
  } = { showSelect: true };

  downloadFiles() {
    if (this.selected.length == 0) return;

    const file = this.selected[0];
    if (!file || !file.doc_id) return;

    file.isDownloading = true;
    this.downloadSingleGpsrFile(file!)
      .then((result: any) => {
        setTimeout(() => {
          this.downloadFiles(); // (AD-210) used this recursion approach to download fiels sequentially, if use `foreach` ALL selected files (10 for example) will generate 10 requests at once
        }, 2000);
      })
      .catch((err: any) => {
        setTimeout(() => {
          this.downloadFiles();
        }, 2000);
        console.error(err);
      })
      .finally(() => {
        this.selected.shift();
        file.isDownloading = false;
      });
  }

  downloadGpsrFileFromList(item: TblUpdateGPSRProdVOFile) {
    if (!item || !item.doc_id) return;

    item.isDownloading = true;
    this.downloadSingleGpsrFile(item)
      .then((result: any) => {})
      .catch((err: any) => {
        console.error(err);
      })
      .finally(() => {
        item.isDownloading = false;
      });
  }

  downloadSingleGpsrFile(element: TblUpdateGPSRProdVOFile) {
    const payload = {
      docId: element.doc_id,
      fileName: element.gpsr_filename,
    };
    return this.actionDownloadArtusFiles(payload); // return Promise for `then/catch` use
  }

  completedFiles() {
    this.selected.forEach((item: any) => {
      if (item.gpsrFileUserNameCompleted?.length == 0) {
        this.actionAddGPSRProdVOFileCompleted({
          id: item.id,
          zrMaster: this.contactForLoggedInUser ? this.contactForLoggedInUser?.zrMaster : null,
          contactEmail: this.azureEmail,
          statusGPSRProdVOFile: this.selectedStatus,
          selectedZRNummer: this.selectedZRNummer,
        });
      }
    });
    this.selected = [];
    setTimeout(() => {
      this.getTblUpdateGPSRProdVOFiles();
    }, 500);
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-form',[_c('v-card-title',{staticClass:"pb-0"},[_c('span',{staticClass:"text-h4 Anthrazit--text"},[_vm._v(_vm._s(_vm.$t('title')))])]),_c('v-card-text',[_c('d4y-table',{attrs:{"isShowTreeNavToolbar":false,"haveMoreActions":true,"searchLabel":_vm.$t('table_search_label'),"tableHasFilterBtn":false,"loadingFacet":_vm.loadingFacet,"hasFooterBtn":false,"loading":_vm.mitgliedersIsLoading,"options":_vm.mitgliedersSearchParams.dataOption,"searchMenuObj":_vm.searchMenuObj,"isFooterBtnDisabled":_vm.isHasSearchData,"serverItemsLength":_vm.getMitglieder.total,"tableHeaderText":_vm.tableText,"isSearchDataEmpty":_vm.isAllFiltersEmpty,"items":_vm.items,"headers":_vm.tableView==0 ? _vm.headers : _vm.altHeaders,"hasBodySlot":false},on:{"update:options":_vm.selectionUpdateOptions,"input":function($event){return _vm.changeSearchInput($event)},"search:chip":_vm.searchInRealObjects,"remove:chip":_vm.removeChip,"click:clear-all-filter":_vm.clearAllFilters,"click:loadViewTable":_vm.selectionViewTable},scopedSlots:_vm._u([{key:"plzGebietFull",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s('PLZ-Gebiet: ' + item.plzGebietFull)+" ")])]}},{key:"zrnummer",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"blue--text text-decoration-underline cursor_pointer",on:{"click":function($event){$event.stopPropagation();return _vm.openSupplier(item.zrNummer)}}},[_vm._v(_vm._s(_vm.$t('konditionen')))])]}},{key:"createdDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.zrBeginn)+" ")]}},{key:"zrEnde",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.zrEnde)+" ")]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { render, staticRenderFns } from "./tblMitglieder.html?vue&type=template&id=1d4390e8&scoped=true&"
import script from "./tblMitglieder.ts?vue&type=script&lang=ts&"
export * from "./tblMitglieder.ts?vue&type=script&lang=ts&"
import style0 from "./tblMitglieder.scss?vue&type=style&index=0&id=1d4390e8&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d4390e8",
  null
  
)

/* custom blocks */
import block0 from "./tblMitglieder.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fviews%2Fmitglieder%2FtblMitglieder.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
installComponents(component, {VCard,VCardText,VCardTitle,VForm})

import { Component, Prop, Watch } from 'vue-property-decorator';
import D4yTable from '@/components/d4y-table/d4y-table.vue';
import { Logger } from 'fsts';
import Vue from 'vue';
import { namespace } from 'vuex-class';
import mitgliederSearchData, {
  MitgliederFacetSearchData,
  MitgliederSearchData,
} from '@/shared/model/smallPayloadModels/mitgliederSearchData';
import { ROUTES } from '@/router/routesEnum';
import DateField from '@/components/_common/date-field/DateField.vue';
import { CONST } from '@/shared/utils/Constants';

import GeneralUtils from '@/shared/utils/generalUtils';

const statusUrlParam = 'Status';
const zrNummerUrlParam = 'zrNummer';
const getObjProperty = (obj: any, prop: string) =>
  Object.entries(obj).find(([key]) => key.toLowerCase() === prop.toLowerCase())?.[1];

const logger = new Logger('login');
const tblMitgliederModule = namespace('tblMitglieder');

@Component({
  components: { D4yTable, DateField },
})
export default class MitgliederComponent extends Vue {
  @tblMitgliederModule.Action('getMitglieder')
  private actionGetMitgliederData!: any;
  @tblMitgliederModule.Getter('getMitglieders')
  private getMitglieder!: any;

  @tblMitgliederModule.Action('updateMitgliederSearchData')
  private actionUpdateMitgliederSearchData!: any;

  @tblMitgliederModule.Action('getMitgliederFacetSearch')
  private actionGetMitgliederFacetSearch!: any;
  @tblMitgliederModule.Getter('isMitgliederSearchDataEmpty')
  private isMitgliederSearchDataEmpty!: any;
  @tblMitgliederModule.Getter('getMitgliedersIsLoading')
  private mitgliedersIsLoading!: boolean;

  @tblMitgliederModule.Getter('getMitgliederSearchData')
  private getMitgliederSearchData!: any;
;
  @tblMitgliederModule.Getter('getMitgliedersSearchParams')
  private mitgliedersSearchParams!: any;

  private facetSearch = false;
  private menuDisabled = true;
  private searchWord = '';
  private menuItems: any = [];
  private chipData: Array<any> = [];
  private loadingFacet = false;
  private searchPayload: any = {};
  private tableView = 0;

  @Watch('getMitgliederSearchData', { deep: true })
  public async onOptionsFilterChanged(newVal: MitgliederSearchData, oldVal: MitgliederSearchData) {
    const areObjectsEqual = GeneralUtils.areObjectsEqual(newVal, oldVal); // decided to ignore 2 props than include logic for them in `search-form` component
    if (areObjectsEqual) {
      return;
    }
    let payload: any = {
      mitgliederSearchData: {
        zrNumbersId: newVal.zrNumbersId,
        statusCompanies: newVal.statusCompanies,    
        viewTable: this.tableView,   
        searchFacetFields: this.mitgliedersSearchParams.andClauseFieldsIds,
      },
    };
    this.searchPayload = payload.mitgliederSearchData;
    this.getMitgliederSearchData.viewTable = this.tableView;
    this.mitgliedersSearchParams.dataOption.page = 1;
    await this.actionGetMitgliederData(payload)
      .then((result: any) => {})
      .catch((err: any) => {
        logger.error(err);
      });
  }

  async created() {
    if (!this.getMitgliederSearchData) {
      this.getMitgliederSearchData = mitgliederSearchData.defaultData(); // `{}` returned  error
    }

    if (getObjProperty(this.$route.query, zrNummerUrlParam) != undefined) {
      let listZrNummerUrlParam: any = [];
      listZrNummerUrlParam.push(getObjProperty(this.$route.query, zrNummerUrlParam));
      this.getMitgliederSearchData.zrNumbersId = listZrNummerUrlParam;
      this.getMitgliederSearchData = Object.assign({}, this.getMitgliederSearchData);
    }

    if (getObjProperty(this.$route.query, statusUrlParam) != undefined) {
      let listStatusUrlParam: any = [];
      listStatusUrlParam.push(getObjProperty(this.$route.query, statusUrlParam));
      this.getMitgliederSearchData.statusCompanies = listStatusUrlParam;
      this.getMitgliederSearchData = Object.assign({}, this.getMitgliederSearchData);
    }

    this.getMitgliederSearchData.viewTable = this.tableView;
    const payload = {
      searchParams: this.mitgliedersSearchParams,
      mitgliederSearchData: this.getMitgliederSearchData,
    };

    await this.actionGetMitgliederData(payload);
  }

  // get items() {
  //   return this.getMitglieder.items;
  // }

  get items() {
    if (this.tableView == 0) {
      return this.getMitglieder.items.map((x: any, i: number) => {
        x.id = i;
        return x;
      });
    } else {
      return this.getMitglieder.items;
    }
  }

  async mounted() {}

  get headers() {
    let headers: {
      text: string | any;
      value: string;
      align?: string;
      class?: string | string[];
      sortable?: boolean;
      width?: string;
    }[] = [
      {
        text: this.$t('anzahl'),
        value: 'expand-action',
        sortable: false,
        width: '10px',
        align: 'center',
      },      
      { text: this.$t('pLZGebiet'), value: 'plzGebietFull' },
    ];
    return headers;
  }

  get altHeaders() {
    let headers: {
      text: string | any;
      value: string;
      align?: string;
      class?: string | string[];
      sortable?: boolean;
      width?: string;
    }[] = [      
      { text: this.$t('name'), value: 'name', width: '250px' },
      { text: this.$t('suchname2'), value: 'suchname2' },
      { text: this.$t('telefon'), value: 'telefon' },
      { text: this.$t('zrNummer'), value: 'zrNummer', width: '65px', align: 'center' },      
      { text: this.$t('pLZGebiet'), value: 'plzGebiet' },
      { text: this.$t('pLZ'), value: 'plz' },
      { text: this.$t('strasse'), value: 'strasse', align: 'center' },
      { text: this.$t('ort'), value: 'ort' },
      { text: this.$t('land'), value: 'land' },  
      { text: this.$t('webseite'), value: 'webseite' },       
      { text: this.$t('status'), value: 'status', width: '155px' },
      { text: this.$t('uStIdNrn'), value: 'uStIdNrn' },
      { text: this.$t('zrBeginn'), value: 'zrBeginn' },
      { text: this.$t('zrEnde'), value: 'zrEnde' },
    ];
    return headers;
  }

  get searchMenuObj() {
    return {
      facetSearch: this.facetSearch,
      menuDisabled: this.menuDisabled,
      menuItems: this.menuItems,
      hasResults: this.hasResults,
      chipData: this.chipData,
    };
  }

  get hasResults() {
    return this.menuItems.length > 0 && !!this.menuItems[0]?.field;
  }

  private async selectionUpdateOptions(newVal: any, action: any) {
    let expandedLists = document.querySelectorAll<HTMLElement>('.mdi-chevron-down.mdi-flip-v');
    expandedLists.forEach((element) => {
      element.click();
    });

    if (action == 'search') {
      this.searchFacetModeInKollegenauslieferung(newVal);
    } else if (action == 'reset') {
      this.resetAllFacetOptionsAndSearch();
      this.getMitgliederData();
    } else {
      if (this.mitgliedersSearchParams !== undefined) {
        this.mitgliedersSearchParams.dataOption = newVal;
        this.getMitgliederData();
      }
    }
  }

  private searchFacetModeInKollegenauslieferung(e: any): void {
    const searchWord: string = e.filter;
    let selectedListItemId = document.querySelector('.v-list-item--highlighted .v-list-item__title')?.id;
    if (selectedListItemId != undefined) {
      let menuItem = this.menuItems.find((x: any) => x.field == selectedListItemId);
      this.facetSearch = false;
      this.searchInRealObjects(menuItem);
      return;
    }
    this.searchWord = searchWord;
    // if user press "enter" key again then make search in all fields
    if (this.facetSearch && this.menuItems.length != 0) {
      this.facetSearch = false;
      // return;
    }
    // handle facet search for words more than 2 letters
    if (searchWord?.length >= 2) {
      // const { start, end } = this.getStartEndPeriodForSearch();
      this.loadingFacet = true;
      this.makeFacetSearch(searchWord)
        .then((result: any) => {
          this.formatResultFromSearch(result);
          this.getMitgliederSearchData.zipCircleShort = result.zipCircleShort;
          this.getMitgliederSearchData.zipCircleShortExist = result.zipCircleShortExist;
          this.facetSearch = true;
          this.menuDisabled = false;
        })
        .finally(() => {
          this.loadingFacet = false;
        });
    } else if (searchWord?.trim().length === 0) {
      // load all invoices if press `Enter` with clear input
      this.getMitgliederData();
    }
  }

  searchInRealObjects(item: any) {
    this.searchInRealKollegenauslieferung(item);
  }

  searchInRealKollegenauslieferung(item: any) {
    const searchParams = this.mitgliedersSearchParams; //this.searchParams;
    let valueSearchWord: string = this.searchWord;
    if (item.field == 'ZipCircle' && this.getMitgliederSearchData.zipCircleShortExist) {
      valueSearchWord = this.getMitgliederSearchData.zipCircleShort;
    }

    searchParams.andClauseFieldsIds.push({
      chipField: item.field,
      searchWord: valueSearchWord,
    });

    if (this.searchWord?.length >= 2 && item.title.length > 0) {
      this.getMitgliederSearchData.searchFacetFields =
        this.mitgliedersSearchParams.andClauseFieldsIds;
        this.getMitgliederSearchData.viewTable = this.tableView;
      this.actionGetMitgliederData({
        searchParams,
        mitgliederSearchData: this.getMitgliederSearchData,
      })
        .then((result: any) => {
          this.menuDisabled = true;
          if (item.field != 'all') {
            let valueSearch: string = this.searchWord;
            if (item.field == 'ZipCircle' && this.getMitgliederSearchData.zipCircleShortExist) {
              valueSearch = this.getMitgliederSearchData.zipCircleShort + '...';
            }
            this.chipData.push({
              chipText: `${item.fieldTranslated}: ${valueSearch}`,
              chipField: item.field,
              searchWord: valueSearch,
            });
          }
        })
        .finally(() => {
          this.resetFacetMenu();
        });
    }
  }

  resetAllFacetOptionsAndSearch() {
    this.resetChips();
    this.resetMenuItems();
    this.resetFacetMenu();
    this.mitgliedersSearchParams.andClauseFieldsIds = [];
    this.mitgliedersSearchParams.filter = '';
  }

  private resetMenuItems() {
    this.menuItems = [];
  }
  private resetChips() {
    this.chipData = [];
  }

  resetFacetMenu() {
    this.menuDisabled = true;
    this.facetSearch = false;
    this.searchWord = '';
  }

  private async getMitgliederData() {
    this.getMitgliederSearchData.viewTable = this.tableView;
    const payload = {
      searchParams: this.mitgliedersSearchParams,
      mitgliederSearchData: this.getMitgliederSearchData,
    }; // sent search request from localStorage on reload
    await this.actionGetMitgliederData(payload)
      .then((result: any) => {
        logger.log('result :>> ', result);
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  async makeFacetSearch(searchWord: any) {
    let payload = {
      searchWord: searchWord,
      chipDataString: this.chipDataString,
    };

    payload = { ...this.getMitgliederSearchData, ...payload };
    return this.actionGetMitgliederFacetSearch(payload);
  }

  get chipDataString() {
    return this.chipData.map((el: any) => `${el.chipField}:${el.searchWord}`).join(',');
  }

  formatResultFromSearch(result: any) {
    const fieldsWithResult = Object.keys(result).filter((key) => result[key] > 0);
    this.resetMenuItems();
    fieldsWithResult.forEach((element: string) => {
      const fieldName = element.charAt(0).toUpperCase() + element.slice(1);
      if (fieldName != 'ZipCircleShort' && fieldName != 'ZipCircleShortExist') {
        let valueSearch: string = this.searchWord;
        if (fieldName == 'ZipCircle' && result['zipCircleShortExist']) {
          valueSearch = result['zipCircleShort'] + '...';
        }
        const fieldNameTranslated = this.$i18n.tc(`facet_search.${fieldName}`);
        this.menuItems.push({
          title: `${fieldName}: ${valueSearch} (${result[element]})`,
          titleTranslated: `${fieldNameTranslated}: ${valueSearch} (${result[element]})`,
          fieldTranslated: fieldNameTranslated,
          field: fieldName,
        });
      }
    });
    if (fieldsWithResult.length === 0) {
      this.menuItems.push({
        titleTranslated: this.$i18n.tc('facet_search.no_results'),
      });
      return;
    }
    this.menuItems.unshift({
      titleTranslated: this.$i18n.tc('facet_search.all'),
      field: 'all',
      title: 'All',
    });
  }

  removeChip(chipText: any) {
    const index = this.chipData.findIndex((x) => x.chipText === chipText);
    this.chipData.splice(index, 1);
    const indexOdata = this.mitgliedersSearchParams.andClauseFieldsIds.findIndex(
      (x: any) => x.chipText === chipText
    );
    this.mitgliedersSearchParams.andClauseFieldsIds.splice(index, 1);

    this.menuItems = [];
    this.menuDisabled = true;
    this.getMitgliederData();
  }

  get tableText() {
    return '';//`${this.getLieferanteng.total} Artikel`;
  }

  clearAllFilters() {
    this.actionUpdateMitgliederSearchData(mitgliederSearchData.defaultData());
    this.resetAllFacetOptionsAndSearch();
  }

  get isAllFiltersEmpty() {
    return (
      this.isMitgliederSearchDataEmpty &&
      !this.mitgliedersSearchParams.filter &&
      !this.chipDataString
    );
  }

  get isHasSearchData() {
    return (
      this.getMitglieder.total === 0 || this.mitgliedersIsLoading || this.isSearchDataHasNoValues()
    );
  }

  isSearchDataHasNoValues() {
    let values = Object.values(this.getMitgliederSearchData);
    let result = values.some((x: any) => (x + '').length > 0);
    return !result;
  }

  getValueZipCircle(value: any) {
    if (value.length <= 23) {
      return value;
    } else {
      let index = value.indexOf(',');
      if (index != -1 && index <= 23) {
        let lastindex = value.lastIndexOf(',', 23);
        return value.substring(0, lastindex);
      } else {
        return value.substring(0, 20) + '...';
      }
    }
  }

  getValueDeliveryColleagueProductgroup(value: any) {
    if (value.length <= 60) {
      return value;
    } else {
      let index = value.indexOf(';');
      if (index != -1 && index <= 60) {
        let lastindex = value.lastIndexOf(';', 60);
        return value.substring(0, lastindex);
      } else {
        return value.substring(0, 55) + '...';
      }
    }
  }

  openSupplier(zrnummer: any) {
    let url: any = 'https://einrichtungspartnerringvme.sharepoint.com/sites/VME-Einkauf/SitePages/konditionen.aspx?' + zrnummer;
    window.open(url, '_blank');
  }
  
  private selectionViewTable(newVal: any) {
    this.tableView = newVal;
    this.getMitgliederData();
  }

}

import { ROUTES } from '@/router/routesEnum';
import tblUpdateScprixFile, { TblUpdateScprixFile } from '@/shared/model/tblUpdateScprixFile';
import { CompletedInfo } from '@/shared/model/tblUpdateSupplier';
import { OdataItems } from '@/shared/model/OdataItems';
import { SearchParams } from '@/shared/model/searchParams';
import { CONST } from '@/shared/utils/Constants';
import Vue from 'vue';
import DateUtils from '@/shared/utils/DateUtils';
import { Contact } from '@/shared/model/contact';
import { Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import GeneralUtils from '@/shared/utils/generalUtils';
import D4yTable from '@/components/d4y-table/d4y-table.vue';
import DateField from '@/components/_common/date-field/DateField.vue';
import searchData from '@/shared/model/smallPayloadModels/searchData';
import { TblUpdateCondition } from '@/shared/model/tblUpdateCondition';

const name = 'tblUpdateScprixFiles-view';
const contactModule = namespace('contact');
const tblUpdateScprixFileModule = namespace('tblUpdateScprixFile');
const tblUpdateSupplierModule = namespace('tblUpdateSupplier');
const tblUpdateConditionModule = namespace('tblUpdateCondition');
const authModule = namespace('auth');

@Component({ name: name, components: { D4yTable, DateField } })
export default class TblUpdateScprixFilesComponent extends Vue {
  @tblUpdateScprixFileModule.Action('getTblUpdateScprixFiles')
  private actionGettblUpdateScprixFiles!: any;
  @tblUpdateScprixFileModule.Getter('getTblUpdateScprixFiles')
  private tblUpdateScprixFiles!: OdataItems<TblUpdateScprixFile>;
  @tblUpdateScprixFileModule.Getter('getTblUpdateScprixFilesSearchParams')
  private tblUpdateScprixFilesSearchParams!: SearchParams;
  @tblUpdateScprixFileModule.Getter('getTblUpdateScprixFilesIsLoading')
  private tblUpdateScprixFilesIsLoading!: boolean;
  @tblUpdateScprixFileModule.Getter('getTblUpdateScprixFilesTotal')
  private tblUpdateScprixFilesTotal!: number;
  @tblUpdateScprixFileModule.Action('getAddScprixFileCompleted')
  private actionAddScprixFileCompleted!: any;
  @tblUpdateConditionModule.Action('updateDashboardDate')
  private actionUpdateConditionDashboardDate!: any;
  @tblUpdateSupplierModule.Action('updateDashboardDate')
  private actionUpdateDashboardDate!: any;
  @tblUpdateScprixFileModule.Action('updateDashboardDate')
  private actionUpdateScprixFileDashboardDate!: any;
  @tblUpdateScprixFileModule.Action('getCanselScprixFileCompleted')
  private actionCanselScprixFileCompleted!: any;
  @tblUpdateScprixFileModule.Action('downloadScripxFile')
  private actionDownloadScripxFile!: any;

  @tblUpdateScprixFileModule.Getter('getDashboardDate')
  private dashboardDate!: any;
  @contactModule.Getter('getContactForLoggedInUser')
  private contactForLoggedInUser!: Contact;
  @tblUpdateScprixFileModule.Getter('getDashboadrdScprixFilesStatus')
  private dashboardScprixFilesStatus!: any;

  @authModule.Getter('azureUser')
  private azureUser!: any;
  @authModule.Getter('azureUserEmail')
  private azureEmail!: any;
  private selectedStatus: string = '';

  dateSearch: any = '';
  private searchTerm = '';
  loadingFiles: boolean = false;

  @Watch('tblUpdateScprixFilesSearchParams.filter', { deep: true })
  public async onOptionsFilterChanged(newValFilter: string, oldValFilter: string) {
    if (newValFilter == oldValFilter) {
      return;
    }
    this.tblUpdateScprixFilesSearchParams.dataOption.page = 1;
    this.actionGettblUpdateScprixFiles({
      searchParams: this.tblUpdateScprixFilesSearchParams,
      scprixSearchData: {
        datum: this.dateSearch,
        contactEmail: this.azureEmail,
        statusScprixFile: this.selectedStatus,
      },
    });
  }

  changeDate(value: any) {
    // prevent strange 2 request with the same value
    if (this.dateSearch == value) {
      return;
    }
    this.dateSearch = value || undefined; // new Date(value).toJSON();
    // this.triggerSearchDataUpdate();
    this.actionGettblUpdateScprixFiles({
      searchParams: this.tblUpdateScprixFilesSearchParams,
      scprixSearchData: {
        datum: this.dateSearch,
        contactEmail: this.azureEmail,
        statusScprixFile: this.selectedStatus,
      },
    });
    this.actionUpdateConditionDashboardDate(value);
    this.actionUpdateDashboardDate(value);
    this.actionUpdateScprixFileDashboardDate(value);
  }

  created() {
    this.dateSearch = this.dashboardDate;
    if (this.dashboardScprixFilesStatus) {
      this.selectedStatus = this.dashboardScprixFilesStatus;
    } else {
      this.selectedStatus = 'all';
    }
  }

  isInitialRequest = true;
  async mounted() {
    var promiseAll = [this.getTblUpdateScprixFiles()];
    await Promise.all(promiseAll);
    this.isInitialRequest = false;
  }

  private async getTblUpdateScprixFiles() {
    await this.actionGettblUpdateScprixFiles({
      searchParams: this.tblUpdateScprixFilesSearchParams,
      scprixSearchData: {
        datum: this.dateSearch,
        contactEmail: this.azureEmail,
        statusScprixFile: this.selectedStatus,
      },
    });
  }

  get headers() {
    let headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
      align?: string;
    }[] = [
      {
        text: this.$t(''),
        value: 'data-table-select',
        sortable: false,
      },
      { text: this.$t('file_name'), value: 'scp_filename', width: '40%' },
      { text: this.$t('file_order'), value: 'scp_fileorder' },
      { text: this.$t('file_daten'), value: 'scp_filedate', width: '100px' },
      { text: this.$t('files_list_completed'), value: 'scprixFileDoneTimeCompleted' },
      {
        text: this.$t('actions'),
        value: 'actions',
        sortable: false,
        width: '2%',
        align: 'center',
      },
    ];
    return headers;
  }

  selected: TblUpdateScprixFile[] = [];
  get isDownload() {
    return this.selected?.length > 0;
  }

  enterSelect(values: any) {
    if (values?.length > 0) {
      this.selected = values;
    } else {
      this.selected = [];
    }
  }

  get image() {
    return {};
    return {
      src: 'img_tblUpdateSupplier.png',
      header: this.$t('title'),
      width: 360,
      height: 200,
    };
  }

  onAdd() {
    this.add();
  }

  add() {
    // this.$router.push({
    //   name: ROUTES.tblUpdateSupplier,
    //   params: {
    //     tblUpdateSupplierId: CONST.emptyGuid,
    //   },
    // });
  }

  get isRealXl() {
    return this.$vuetify.breakpoint.width < 1920;
  }

  get getFilterFields() {
    return this.tblUpdateScprixFilesSearchParams.orClauseFieldsIds
      .map((x) => this.$t(GeneralUtils.camelToSnakeCase(x)))
      .toString();
  }

  private async tblUpdateScprixFilesUpdateOptions(newVal: any, oldVal: any) {
    this.tblUpdateScprixFilesSearchParams.dataOption = newVal;
    // `oldVal` is always `undefined`
    if (newVal != oldVal && this.tblUpdateScprixFilesIsLoading != undefined && !this.isInitialRequest)
      this.getTblUpdateScprixFiles();
  }

  private async completedScprixFile(tblUpdateScprixFile: any) {
    if (tblUpdateScprixFile.scprixFileDoneTimeCompleted?.length == 0) {
      this.actionAddScprixFileCompleted({
        id: tblUpdateScprixFile.id,
        zrMaster: this.contactForLoggedInUser ? this.contactForLoggedInUser?.zrMaster : null,
        contactEmail: this.azureEmail,
        statusScprixFile: this.selectedStatus,
      }).then((completedInfo: CompletedInfo) => {
        tblUpdateScprixFile.scprixFileDoneTimeCompleted = DateUtils.formatDateTimeWithLocaleAndTimezone(
          completedInfo.completedDoneTime
        );
        tblUpdateScprixFile.scprixFileUserNameCompleted = completedInfo.completedUserName;
        this.getTblUpdateScprixFiles();
      });
    } else {
      this.$confirm
        .open(`${this.$t('completed_dialog_title')}`, `${this.$t('completed_dialog_title_text')}`, {
          cancelText: this.$t('delete_dialog_cancel'),
          okText: this.$t('delete_dialog_ok'),
        })
        .then(async (response: any) => {
          if (response) {
            this.actionCanselScprixFileCompleted({
              id: tblUpdateScprixFile.id,
              zrMaster: this.contactForLoggedInUser ? this.contactForLoggedInUser?.zrMaster : null,
              contactEmail: this.azureEmail,
              statusScprixFile: this.selectedStatus,
            }).then((completedInfo: CompletedInfo) => {
              tblUpdateScprixFile.scprixFileDoneTimeCompleted = '';
              tblUpdateScprixFile.scprixFileUserNameCompleted = '';
              this.getTblUpdateScprixFiles();
            });
          }
        });
    }
  }

  get title() {
    return this.$t('title');
  }

  changeSelectStatus() {
    this.getTblUpdateScprixFiles();
  }

  get labelTitel() {
    if (this.$route.fullPath.includes('/suppliers')) {
      return this.$t('supplier_status');
    } else {
      return this.$t('employee_status');
    }
  }

  closeScprixFiles() {
    this.tblUpdateScprixFilesSearchParams.filter = '';
    this.$router.push({
      name: ROUTES.tasks,
    });
  }

  private changeSearch(searchTerm: string) {
    if (searchTerm.length < 2) return;
    this.searchScprixFiles(searchTerm);
  }
  private changeSearchInput(value: string) {
    if (value == null || value.length == 0) {
      this.resetSearch();
    }
  }
  private resetSearch() {
    const emptyString = '';
    this.searchScprixFiles(emptyString);
  }

  private async searchScprixFiles(filterValue: string) {
    this.tblUpdateScprixFilesSearchParams.filter = filterValue; // `this.get ContactsSearch` will be called via `@Watch`
    // if (!this.contactsIsLoading) this.get ContactsSearch();
  }

  get titleSearch() {
    if (this.$route.fullPath.includes('/tasks/werbung')) {
      return this.$t('search_Werbung');
    } else {
      return this.$t('search_Document');
    }
  }

  openScprixFile(item: TblUpdateScprixFile) {
    if (item.isDownloading) return;

    // let url: any =
    //   'https://intranet.meinvme.de/php/intranet/ZAblage/SCANPRIX/2025/' + item.scp_fileorder + '/' +
    //   item.scp_filename;
    // url ='https://pdfobject.com/pdf/sample.pdf';
    // url='https://www2.hu-berlin.de/stadtlabor/wp-content/uploads/2021/12/sample3.docx';
    // window.open(url, '_blank');
    var payload = {
      fileName: item.scp_filename,
      fileFolder: item.scp_fileorder,
    };
    // (AD-201) different download logic for `localDev`(stub link) and other ENVs(download from Azure)

    item.isDownloading = true;
    this.actionDownloadScripxFile(payload).finally(() => {
      setTimeout(() => {
        item.isDownloading = false;
      }, 500);
    });
  }

  activeState = [
    {
      text: this.$i18n.t(`select_all`), //'Alle',
      value: 'all',
    },
    {
      text: this.$i18n.t(`select_offen`), //'Offen',
      value: 'open',
    },
    {
      text: this.$i18n.t(`select_erledigt`), //'Erledigt',
      value: 'done',
    },
  ];

  private selection?: {
    showSelect: boolean;
    singleSelect?: boolean;
    customHeader?: boolean;
  } = { showSelect: true };

  downloadFiles() {
    this.selected.forEach((item: any) => {
      this.openScprixFile(item);
    });
    this.selected = [];
  }

  completedFiles() {
    this.selected.forEach((item: any) => {
      if (item.scprixFileUserNameCompleted?.length == 0) {
        this.actionAddScprixFileCompleted({
          id: item.id,
          zrMaster: this.contactForLoggedInUser ? this.contactForLoggedInUser?.zrMaster : null,
          contactEmail: this.azureEmail,
          statusScprixFile: this.selectedStatus,
        });
      }
    });
    this.selected = [];
    setTimeout(() => {
      this.getTblUpdateScprixFiles();
    }, 500);
  }
}
